import React, { FunctionComponent, useEffect, useState } from 'react';
import GridProductCard from '@Components/Card/cards/ProductCard/subcomponents/GridProductCard/GridProductCard';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import ListProductCard from '@Components/Card/cards/ProductCard/subcomponents/ListCategoryCard/ListProductCard';
import {
	PriceType,
	ProductCardWrapper,
	ProductType,
	ViewType,
} from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { getViewType } from '@Redux/modules/settings/viewType/selectors';
import { Link } from 'react-router-dom';
import { PRODUCT_PAGE_ITEM_FLAG, PRODUCT_PAGE_QUERY_COLOR_PARAM } from '@Views/ProductPage/ProductPage';
import { IPricesWithVat } from './subcomponents/PriceSection/PriceSection';
import { useSelector } from 'react-redux';
import { CAPACITY_ATTRIBUTE, DEF_ITEM_TYPE, GIFT_CARD_ITEM_TYPE } from '@ApiModels/utils/attributeGetters';
import { ITEMS_LIST } from '@ApiModels/relatedProductsById';
import { getLang } from '@Utils/language/language';
import { dispatch } from '@Redux/store';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import { PlanType } from '@Components/Card/cards/Plan/Plan';
import { ESHOP_MARKETING_TAG, iFrameHost, isIFrameAppleWatch } from '@Config/app.config';

const ProductCard: FunctionComponent<types.cards.IProductCardProps> = ({
	params: {
		tileSize,
		priceType = PriceType.GROUP,
		buttonAction,
		preorderStartDate,
		product,
		iconsHidden,
		isRelatedProduct,
		layoutChange,
		inventory,
		showInstalment,
	},
}) => {
	const [selectedColorId, setSelectedColorId] = useState<string | undefined>(undefined);
	const [thumbnailUrl, setThumbnailUrl] = useState<string>(product.thumbnail);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [productType, setProductType] = useState<ProductType>(ProductType.AVAILABLE);
	const [selectedColor, setSelectedColor] = useState<string | null>(null);
	const [pricesWithVat, setPricesWithVat] = useState<IPricesWithVat>({
		offerPrice: '0',
		listPrice: '0',
		taxablePrice: '0',
		vatPercent: '0',
		vatValue: '0',
	});
	const { availableCapacities, availableColors, colors } = product;
	const capacityFilters = useSelector((state: types.redux.IState) =>
		state.filters.find((filter) => filter.extendedData.propertyvalue === CAPACITY_ATTRIBUTE)
	);
	const [skuPrice, setSkuPrice] = useState(false);
	const eshopMarketingTag: any | string = product?.attributes?.find((attr) => attr.identifier === ESHOP_MARKETING_TAG)
		?.values[0].identifier;

	useEffect(() => {
		setSelectedColor(null);
	}, [product]);

	useEffect(() => {
		const enabledCapacityFilters = capacityFilters?.entry.filter((entry) => entry.enabled);
		if (enabledCapacityFilters?.length) {
			setSkuPrice(enabledCapacityFilters.length === 1);
			const selectedCapacity = product.availableCapacity.filter(
				(cap) =>
					cap.parsedIdentifier ===
					enabledCapacityFilters.sort((a, b) =>
						Number(a.parsedValue.replace('GB', '').trim()) < Number(b.parsedValue.replace('GB', '').trim())
							? -1
							: 1
					)[0].parsedValue
			);
			if (selectedCapacity.length && availableCapacities?.length) {
				const _selected = availableCapacities.find((cap) => cap.id === selectedCapacity[0].id);
				if (_selected?.price) {
					setPricesWithVat(_selected.price);
				}
			}
		} else if (selectedColor) {
			setSkuPrice(false);
			const _color = availableColors?.find((color) => color.color === selectedColor);
			setThumbnailUrl(_color?.thumbnail ?? '');
			setSelectedColorId(_color?.id ?? undefined);

			if (_color) {
				const { offerPrice, listPrice, taxablePrice, vatPercent, vatValue } = _color;
				setPricesWithVat({ offerPrice, listPrice, taxablePrice, vatPercent, vatValue });
			}
		}
	}, [capacityFilters]);

	useEffect(() => {
		const isGiftCard = Array.isArray(product.itemType)
			? product.itemType?.includes(GIFT_CARD_ITEM_TYPE)
			: product.itemType === GIFT_CARD_ITEM_TYPE;
		if (
			availableColors?.length &&
			availableColors.find((color) => color.color === selectedColor)?.available &&
			!product.preorder
		) {
			setProductType(ProductType.AVAILABLE);
		} else if (!availableColors?.length && (product.isItem || isGiftCard)) {
			const inventoryId = product.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)?.id;
			if (inventoryId && inventory) {
				const productInventory = inventory[inventoryId];
				if (productInventory?.some((inv) => !!Number(inv.value) && Number(inv.value) > 0)) {
					setProductType(ProductType.AVAILABLE);
				} else {
					setProductType(ProductType.UNAVAILABLE);
				}

				if (productInventory) {
					const { offerPrice, listPrice, taxablePrice, vatPercent, vatValue } = productInventory[0];
					setPricesWithVat({ offerPrice, listPrice, taxablePrice, vatPercent, vatValue });
				} else {
					setPricesWithVat({
						offerPrice: '0',
						listPrice: '0',
						taxablePrice: '0',
						vatPercent: '0',
						vatValue: '0',
					});
				}
			}
		} else if (product.itemType === 'SALES_LEAD_DEVICE') {
			setProductType(ProductType.SALES_LEAD);
		} else {
			setProductType(ProductType.UNAVAILABLE);
		}

		const _color = availableColors?.find((color) => color.color === selectedColor);

		if (_color) {
			setThumbnailUrl(_color?.thumbnail ?? product.thumbnail ?? '');
			setSelectedColorId(_color?.id ?? undefined);
			if (!product.preorder) {
				setProductType(_color.available ? ProductType.AVAILABLE : ProductType.UNAVAILABLE);
			}
			const { offerPrice, listPrice, taxablePrice, vatPercent, vatValue } = _color;
			setPricesWithVat({ offerPrice, listPrice, taxablePrice, vatPercent, vatValue });
		} else if (availableColors?.length) {
			const index = availableColors.findIndex((color) => color.available);
			const { offerPrice, listPrice, taxablePrice, vatPercent, vatValue } =
				availableColors[index] ?? availableColors[0];
			setPricesWithVat({ offerPrice, listPrice, taxablePrice, vatPercent, vatValue });
			if (!product.preorder) {
				setProductType(index >= 0 ? ProductType.AVAILABLE : ProductType.UNAVAILABLE);
			}
			setThumbnailUrl((availableColors[index] ?? availableColors[0]).thumbnail);
			if ((colors[index] ?? colors[0])?.color) {
				setSelectedColor((colors[index] ?? colors[0])?.color);
			}
		} else {
			setThumbnailUrl(product.thumbnail ?? '');
		}

		if (isRelatedProduct && !product.availableCapacity.length && !product.availableColors?.length) {
			const defItemType = product?.attributes?.find((attr) => attr.identifier === 'DEF_ITEM_TYPE')?.uniqueID;
			if (product?.inventory && product?.inventory[defItemType || 0]) {
				const inventory = product?.inventory[defItemType || 0][0];
				const { value, offerPrice, listPrice, taxablePrice, vatPercent, vatValue } = inventory;
				if (Number(value) > 0) {
					setProductType(ProductType.AVAILABLE);
				}
				setPricesWithVat({ offerPrice, listPrice, taxablePrice, vatPercent, vatValue });
			}
		}

		if (product.preorder) {
			setProductType(ProductType.PREORDER);
		}

		setIsLoading(false);
	}, [availableColors, selectedColor, product]);

	const getLink = () => {
		let isDigitalOrGC = false;
		if (Array.isArray(product.itemType)) {
			isDigitalOrGC = product.itemType.some((item) => ITEMS_LIST.includes(item));
		} else if (typeof product.itemType === 'string') {
			isDigitalOrGC = ITEMS_LIST.includes(product.itemType);
		}
		if (selectedColorId) {
			return `${iFrameHost}/${getLang()}/product${
				product.seo
			}?${PRODUCT_PAGE_QUERY_COLOR_PARAM}=${selectedColorId}&${PRODUCT_PAGE_ITEM_FLAG}=${isDigitalOrGC}`;
		} else {
			return `${iFrameHost}/${getLang()}/product${product.seo}?${PRODUCT_PAGE_ITEM_FLAG}=${isDigitalOrGC}`;
		}
	};

	const clearProductPage = () => {
		dispatch(
			setSelectedItems({
				details: undefined,
				planType: PlanType.DEVICE_ONLY,
				wrongDeviceChoosen: false,
				currentPlan: undefined,
				plan: undefined,
				isHBB: false,
				isWFBB5G: false,
				isWFBB4G: false,
				showWarning: false,
				userHaveLegacyPlan: false,
				userPlanWarning: '',
				cantBeUpgradedPlanId: undefined,
			})
		);
	};

	const handleSeeDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		clearProductPage();
		e?.stopPropagation();
		if (buttonAction) {
			buttonAction();
		}
	};

	const props = {
		product,
		tileSize,
		selectedColor,
		setSelectedColor,
		productType,
		availableColors,
		priceType: skuPrice ? PriceType.SINGLE : priceType,
		thumbnailUrl,
		layoutChange,
		isLoading,
		handleSeeDetails,
		iconsHidden,
		pricesWithVat,
		withoutColorPicker: !!product.isGiftCard || !!product.isDigitalProduct,
		eshopMarketingTag,
		showInstalment,
	};

	return (
		<Link
			to={isIFrameAppleWatch ? { pathname: getLink() } : getLink()}
			target={isIFrameAppleWatch ? '_parent' : undefined}
		>
			<ProductCardWrapper onClick={() => handleSeeDetails()}>
				<ConditionalRender
					show={getViewType() === ViewType.GRID}
					onTrue={() => <GridProductCard preorderStartDate={preorderStartDate} {...props} />}
					onFalse={() => <ListProductCard {...props} />}
				/>
			</ProductCardWrapper>
		</Link>
	);
};

export default ProductCard;
